import React, { FC } from "react";
import { useRouter } from "next/router";
import { ChevronIconSvg } from "../../icons";
import { Form } from "react-final-form";
import { FieldInput } from "../../fields/FieldInput";
import { Button } from "../../Button";
import { Heading } from "../../Heading";
import {
  BrandConfigCustomizationsImage,
  FormsForgotPasswordLocalization,
  FormsValidationLocalization,
  PortalAltsLocalization,
} from "../../../types/graphql/generated";
import {
  validateWithYup,
  getForgotPasswordSchema,
} from "../../../helpers/validation";
import { TemplateAsComponent } from "../../TemplateAsComponent";
import { colors } from "../../../styles/variables";
import {
  StyledAccountLogo,
  StyledExternalLink,
  StyledForgotPasswordForm,
  StyledTitle,
  StyledText,
  StyledForm,
  StyledError,
  StyledLinkWrapper,
} from "./index.styled";

interface ForgotPasswordFormProps {
  accountLogo?: BrandConfigCustomizationsImage;
  contactLinkUrl?: string;
  fallbackUrl?: string;
  isLoading: boolean;
  isSubmitted: boolean;
  error: string;
  onSubmit: (formValue: { email: string }) => void;
  toLogin: () => void;
  forgotPasswordLocalization?: FormsForgotPasswordLocalization | null;
  altsLocalization?: PortalAltsLocalization | null;
  validationLocalization?: FormsValidationLocalization | null;
}

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  accountLogo,
  contactLinkUrl,
  isLoading,
  isSubmitted,
  error,
  onSubmit,
  toLogin,
  forgotPasswordLocalization,
  altsLocalization,
  validationLocalization,
}) => {
  const {
    headingText = "Forgot Password",
    submittedText = "Thank you. If a matching email exists we will send you an email in a few minutes with instructions on how to reset your password.",
    // eslint-disable-next-line no-template-curly-in-string
    contactLinkText = "Please check your spam folder if you cannot find the email. For further assistance, please ${linkText}.",
    contactLinkContentText = "contact us",
    formDescriptionText = "Enter your email and we will send you a password reset link",
    emailLabelText = "Email Address",
    submitButtonText = "Send",
    backToLoginText = "Back to Login",
  } = forgotPasswordLocalization || {};
  const { accountAltText = "Account" } = altsLocalization || {};
  const router = useRouter();

  return (
    <StyledForgotPasswordForm>
      {!!accountLogo && (
        <div>
          <StyledLinkWrapper
            onClick={() => {
              router.back();
            }}
          >
            <ChevronIconSvg />
          </StyledLinkWrapper>
          <StyledAccountLogo src={accountLogo.src} alt={accountAltText} />
        </div>
      )}
      <StyledTitle>
        <Heading level="h2" size="l" color={colors.gray800}>
          {headingText}
        </Heading>
      </StyledTitle>

      {isSubmitted ? (
        <>
          <p data-testid="forgot-password-submitted">{submittedText}</p>
          {contactLinkUrl && (
            <TemplateAsComponent
              template={contactLinkText}
              map={{
                linkText: (
                  <StyledExternalLink href={contactLinkUrl}>
                    {contactLinkContentText}
                  </StyledExternalLink>
                ),
              }}
            />
          )}
        </>
      ) : (
        <>
          <StyledText>{formDescriptionText}</StyledText>
          <Form
            onSubmit={onSubmit}
            validate={validateWithYup(
              getForgotPasswordSchema(validationLocalization)
            )}
            render={({ handleSubmit }) => (
              <StyledForm onSubmit={handleSubmit}>
                <FieldInput
                  label={emailLabelText}
                  name="email"
                  autoComplete="email"
                />
                <Button htmlType="submit" loading={isLoading} fullWidth>
                  {submitButtonText}
                </Button>
                {error && <StyledError>{error}</StyledError>}
              </StyledForm>
            )}
          />
          <Button type="tertiary" fullWidth onClick={toLogin}>
            {backToLoginText}
          </Button>
        </>
      )}
    </StyledForgotPasswordForm>
  );
};

import styled from "styled-components";
import { ExternalLink } from "../../links/ExternalLink";

export const StyledAccountLogo = styled.img`
  margin-bottom: 70px;
`;

export const StyledLinkWrapper = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
  > :hover {
    cursor: pointer;
  }
`;

export const StyledForgotPasswordForm = styled.div`
  margin: auto;
  max-width: 448px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  button:last-child {
    margin-top: 15px;
  }
`;

export const StyledTitle = styled.div`
  margin-bottom: 10px;
  align-self: center;
`;

export const StyledText = styled.p`
  text-align: center;
  font-family: inherit;
  color: ${({ theme }) => theme.color.disabled};
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.color.primary};
`;

export const StyledError = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.error};
`;

import React, { FC, useRef, useEffect } from "react";
import {
  Field,
  useField,
  FieldInputProps as FinalFormInputProps,
  FieldMetaState,
} from "react-final-form";
import {
  StyledField,
  StyledInput,
  StyledLabel,
  StyledError,
  StyledInlineInputWrapper,
  StyledInlineInputText,
  StyledInputWrapper,
} from "../base.styled";
import { FieldProps } from "../../../types/field";

interface FieldInputProps extends FieldProps {
  input?: FinalFormInputProps<string>;
  defaultValue?: string;
  autocompleteValue?: string;
  maxLength?: number;
  browserAutofillOffValue?: string;
  placeholder?: string;
  backgroundColor?: string;
  inlineInputText?: string;
  onMetaChange?: (meta: FieldMetaState<string>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OnChangeType = (event: any) => void;

export const FieldInput: FC<FieldInputProps> = ({
  name,
  defaultValue,
  autocompleteValue,
  browserAutofillOffValue,
  maxLength,
  placeholder,
  backgroundColor,
  inlineInputText,
  onMetaChange,
  ...props
}) => {
  const inputOnChange = useRef<OnChangeType | null>(null);
  const { meta } = useField(name);

  useEffect(() => {
    onMetaChange?.(meta);
  }, [onMetaChange, meta]);

  // handle an incoming change from the AutocompleteInput
  useEffect(() => {
    if (inputOnChange.current && autocompleteValue !== undefined) {
      inputOnChange.current(autocompleteValue);
    }
  }, [autocompleteValue, inputOnChange]);

  return (
    <Field
      name={name}
      {...props}
      defaultValue={defaultValue}
      label={props.label}
    >
      {({ input, meta }) => {
        if (!inputOnChange.current) {
          inputOnChange.current = input.onChange;
        }

        return (
          <StyledField>
            <StyledLabel>
              {props.label && <div>{props.label}</div>}
              <StyledInlineInputWrapper>
                {inlineInputText && (
                  <StyledInlineInputText>
                    {inlineInputText}
                  </StyledInlineInputText>
                )}
                <StyledInputWrapper>
                  <StyledInput
                    id={name}
                    className="input-field"
                    type="text"
                    {...input}
                    {...props}
                    valid={meta.touched && !meta.error}
                    invalid={meta.touched && meta.error}
                    onChange={input.onChange}
                    autoComplete={browserAutofillOffValue}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    backgroundColor={backgroundColor}
                  />
                </StyledInputWrapper>
              </StyledInlineInputWrapper>
            </StyledLabel>
            {meta.touched && meta.error && (
              <StyledError>{meta.error}</StyledError>
            )}
          </StyledField>
        );
      }}
    </Field>
  );
};
